@import "../../res/colors";

.container{
  width: 900px;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 4px 4px black_opacity(0.03);
  padding: 40px 0 10px 0;
  flex-shrink: 0;

  h2{
    color: $dark_blue;
    font-weight: 700;
    font-size: 26px;
  }

  p{
    color: $gray-medium;
    transition: color 200ms;
    -webkit-transition: color 300ms;
  }

  .stateBand{
    width: 100%;
    position: relative;
    z-index: 100;
    height: 35px;
    background-color: $gray-thin;
    margin-top: 10px;
    padding: 0 10% 0 10%;

    .stateContainer{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $gray-medium;
      font-weight: 600;

      span {
        width: 150px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
      }

      .stateMask{
        position: absolute;
        top: -5px;
        left: -50px;
        width: 200px !important;
        height: 45px;
        background-color: $blue;
        border-radius: 3px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 16px 26px -10px $blue, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px blue_opacity(0.1);

        transition: left 300ms;
        -webkit-transition: left 300ms;
      }

      .stateMaskActive{
        width: 200px !important;
        height: 45px;
        background-color: $blue;
        border-radius: 3px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 16px 26px -10px $blue, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px blue_opacity(0.1);
        left: -75px;
        position: relative;
      }

      .lastSpanActive {
        left: 60px;
        width: 200px !important;
        height: 45px;
        background-color: $blue;
        border-radius: 3px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 16px 26px -10px $blue, 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px blue_opacity(0.1);
        position: relative;
      }
    }
  }

  .content{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form{
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .control {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10% 0 10%;
      margin-top: 40px;

      button {
        width: 200px;
        height: 40px;
        border-radius: 4px;
        color: $gray-medium;
        background-color: $gray-thin;
        border: 1px solid $gray-thin;
        transition: background-color 200ms;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button:hover{
        border: 1px solid $gray-thin !important;
        background-color: white;
      }

      .important{
        background-color: $blue;
        border: 1px solid $blue;
        color: white;
      }

      .important:hover{
        border: 1px solid $blue;
        background-color: white !important;
        color: $blue;
      }
    }
  }
}