$blue: rgb(29,195,231);
@function blue_opacity($opacity){
  @return rgba(29,195,231, $opacity);
}
$dark_blue: #394C5F;

$orange: rgb(246, 189, 96);
@function orange_opacity($opacity){
  @return rgba(246,189,96, $opacity);
}

$dark: #272727;

$black: rgb(40,40,40);
@function black_opacity($opacity){
  @return rgba(40,40,40, $opacity);
}

$gray-strong: rgb(70,70,70);
$gray-medium: rgb(120,120,120);
$gray-little: rgb(200,200,200);
$gray-thin: #F0F0F0;