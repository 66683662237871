@import '../../../res/colors';
@import '../material-style';


.input {
  .group {
    .dropdown {
      position: absolute;
      z-index: 500;
      background-color: white;
      width: 100%;
      height: 0;
      padding: 0;
      overflow-y: auto;
      max-height: 200px;

      li {
        cursor: pointer;
        text-align: center;
        list-style-type: none;
        padding: 5px 0 5px 5px;
        color: $gray-strong;
      }

      li:hover {
        color: white;
        background-color: $blue;
      }
    }

    input:focus {
      outline: none;

      & ~ .dropdown {
        height: 150px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
        transition: 0.2s ease-out height, step-start, box-shadow 0.2s;
        -moz-transition: 0.2s ease-out height, step-start, box-shadow 0.2s;
        -webkit-transition: 0.2s ease-out height, step-start, box-shadow 0.2s;
      }
    }
  }
}