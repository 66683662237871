@import "/res/colors.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  width: 100vw;
  height: 100vh;
  background-color: $dark !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img{vertical-align: 0 !important}

a{
  text-decoration: none;
}

#auth-loading{
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
  margin-top: 200px;

  img{
    width: 100%;
  }

  h1{
    font-size: 22px;
    margin-top: 5px;
    font-weight: bold;
  }

  div{
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 20px;
      color: white;
      margin-left: 10px;
    }
  }
}

#app{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white !important;

  #app_content{
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;

    .page{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      background-color: $gray-thin;
      width: 100%;
      height: 100%;
      overflow-y: scroll;

      transition: background-color 300ms;
      -webkit-transition: background-color 300ms;
    }
  }
}