@import '../../res/colors';

.input {
  display: flex;
  align-items: center;
  width: 45%;
  min-width: 200px;

  .icon{
    color: $gray-medium;
    width: 24px;
    height: 24px;
  }

  .group {
    position: relative;
    margin: 0 0 30px 20px;
    width: 100%;

    input {
      font-size: 18px;
      padding: 10px 10px 10px 5px;
      display: block;
      width: 100%;
      border: none;
      border-bottom: 1px solid #757575;
      background-color: white;
    }

    input:focus {
      outline: none;
    }

    label {
      color: $gray-little;
      font-size: 18px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 10px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;

      .label-icon{
        height: 15px;
      }
    }

    input:focus ~ label, input:valid ~ label {
      top: -20px;
      font-size: 14px;
      color: $blue;
    }

    .bar {
      position: relative;
      display: block;
      width: 100%;
    }

    .bar:before, .bar:after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: $blue;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    .bar:before {
      left: 50%;
    }

    .bar:after {
      right: 50%;
    }

    input:focus ~ .bar:before, input:focus ~ .bar:after {
      width: 50%;
    }

    .highlight {
      position: absolute;
      height: 60%;
      width: 100px;
      top: 25%;
      left: 0;
      pointer-events: none;
      opacity: 0.5;
    }

    input:focus ~ .highlight {
      -webkit-animation: inputHighlighter 0.3s ease;
      -moz-animation: inputHighlighter 0.3s ease;
      animation: inputHighlighter 0.3s ease;
    }
  }
}

@-webkit-keyframes inputHighlighter {
  from { background:$blue; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:$blue; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:$blue; }
  to 	{ width:0; background:transparent; }
}